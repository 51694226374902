/*------------------------------------*\
    #PAGE
\*------------------------------------*/

html {
    font-family:poppins, sans-serif;
    font-weight: 400;
    overflow-x: hidden;
}
body {
    width: 100%;
    @include media-query(palm) {
        overflow-x: hidden;
    }
}
@include media-query(palm){
    .js-nav-main-state, .search__header{
        margin-top: 9px;
    }
    .page__body{
        margin-top:25px;
    }
}