///*------------------------------------*\
//    #DETAIL-HOTEL
//\*------------------------------------*/

.detail-hotel__content {
    @include media-query(desk) {
        @include span(4 of 6); 
    }
}
.detail-hotel__sidebar {
    @include span(2 of 6);
    @include media-query(portable) {
        @include span(full);  
    }
}





.detail-hotel__title {
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}
.detail-hotel__label {
    font-size: 12px;
}
.detail-hotel__price {
    color: white;
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit--small; 
    }
}





.detail-hotel__carousel {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        margin-bottom: -1px;
        margin-left: -$inuit-base-spacing-unit--small;
        margin-right: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.detail-hotel__carousel__image {
    // background: url() no-repeat center center;
    height: 260px;
    background-size: cover;
    @include media-query(palm) { 
        height: 220px;
    }
}





.detail-hotel__rooms-table {
    margin-bottom: $inuit-base-spacing-unit;
}




.detail-hotel__map {
    border-radius: $base-round;
    border: 1px solid rgba(0,0,0,0.07);
    height: 300px;
    margin-bottom: $inuit-base-spacing-unit;
    width: 100%;
    @include media-query(palm) {
        height: 200px;
    }
}
.detail-hotel__description {
    padding-bottom: $inuit-base-spacing-unit; 
}
.detail-hotel__list {
    padding-bottom: $inuit-base-spacing-unit;
}