///*------------------------------------*\
//    #SLIDEPANEL
//\*------------------------------------*/
.slidePanel {
    background-color: white;
    height: 100%;
    padding: 0;
    position: fixed;
    top: 0 !important;
    width: 100%;

    transform: translate(0px, 0px);
    transition: 0.2s ease;
}
.slidePanel--menu {
    height: calc(100% - 150px);
    min-height: 670px;
    margin-top: 150px;
    position: absolute;
}
.slidePanel.slidePanel--left {
    left: -100%;
}
.slidePanel.slidePanel--right {
    right: -100%;
    @include media-query(lap-and-up) {
        border-left: 1px solid rgba(0,0,0,0.1);
        right: -400px;
        width: 400px;
        box-shadow: 10px 10px 10px 10px rgba(0,0,0,0.1);
    }
}
.slidePanel.slidePanel--full {
    right: -100%;
    width: 100%;
}
.slidePanel--left.active {
    z-index: $z-index-slidepanel;
    transform: translate(100%, 0px);
}
.slidePanel--right.active {
    z-index: $z-index-slidepanel;
    transform: translate(-100%, 0px);
}
.slidePanel--left.active-noTranslate {
    left: 0;
    transition: none;
}
.slidePanel .slidePanel-header {
    background-color: white;
    border-bottom: 1px solid $color-slidepanel-border;
    padding: $inuit-base-spacing-unit--small;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-index-slidepanel__header;
    box-shadow: 0 3px 3px -2px $color-fixed-border;
}
.slidePanel .slidePanel-body {
    background-color: white;
    height: 100%;
    left: 0 !important;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: $inuit-base-spacing-unit;
    position: relative;
    top: 0;
    width: 100%;
    @include media-query(lap-and-up) {
        padding: $inuit-base-spacing-unit;
    }
}
.slidePanel-body--menu {
    overflow-y: hidden;
}
.slidePanel .slidePanel-body.is-withHeader {
    padding-top: $inuit-base-spacing-unit--huge + $inuit-base-spacing-unit--small ;
}