/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a, .link-span {
    transition: color .2s;
    text-decoration: none;
    color: $color-links;
    cursor: pointer;
    @include attention() {
        color: darken($color-links, 10%);
    }
    &.is-active {
        color: darken($color-links, 10%);
    }
    &.is-disabled {
        color: $color-links-disable;
        cursor: default;
        @include attention() {
            color: $color-links-disable;
        }
    }
}

@mixin link-quiet ($color-link: $color-links-quiet) { 
    font-weight: normal;  
    transition: color .2s;
    text-decoration: none;
    color: $color-link;
    @include attention() {
        color: darken($color-link, 10%);
    }

}

.link-quiet {
    @include link-quiet();
    cursor: pointer;
}

.link-secret {
    color: inherit;
    @include font-weight(regular);
    @include attention() {
        color: $color-links;
        text-decoration: none;
    }
}

.link-slim {
    @extend .link-span;
    font-weight: 400;
}

.btn-link {
    display: inline-block;
    color: unset;
    transition: 0s;
    @include attention() {
        color: unset;
    }
}