///*------------------------------------*\
//    #SEARCH
//\*------------------------------------*/


.search__title {
    @include media-query(palm) {
        color: $color-main;
    }
}
.search__light--box{
    @include media-query(lap-and-up) {       
        & .btn-group > .btn--inactive {
            color: $color-main;         
        }               
    }
}
.search__link-add-multi {
    margin-top: $inuit-base-spacing-unit--small;
}

.search-shared__datepicker-container {
    display: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: absolute;
    padding: 9px 9px 0 9px;
    width: 820px;
    z-index: $z-index-datepicker-container;
    box-shadow: 0px 10px 50px -17px rgba(0,0,0,0.75);

    @include media-query(portable) {
        width: calc(100% - 20px);
        max-width: 420px;
    }
}
.search-shared_datepicker-arrow-top {
    display: none;
    position: absolute;
    left: 30px;
    bottom: -15px;
    height: 20px;
    z-index: $z-index-datepicker-arrow;
}