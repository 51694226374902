///*------------------------------------*\
//    #NAV-FOOT
//\*------------------------------------*/

.nav-foot-comp{
//    background-image: url("../img/promo/compagnies/compagny.jpg");
    background-repeat: no-repeat;
    background-size: 900%;
    background-position: 0 0;
    padding-top: 71%;
    transition: all 0.3s ease;
}