/*------------------------------------*\
    #WRAPPERS
\*------------------------------------*/

$wrapper-padding-spacing-unit: $inuit-base-spacing-unit;
$wrapper-padding-spacing-unit--small: $inuit-base-spacing-unit--small;


.wrapper {
    @include container(1200px);
    padding-right: $wrapper-padding-spacing-unit--small - 4px;
    padding-left:  $wrapper-padding-spacing-unit--small - 4px;

    @include media-query(lap-and-up) {
        padding-right: $wrapper-padding-spacing-unit;
        padding-left:  $wrapper-padding-spacing-unit;
    }
}

.wrapper-susy {
    clear: both;
    margin-left: -$wrapper-padding-spacing-unit--small;
    margin-right: -$wrapper-padding-spacing-unit--small;
}
.wrapper-susy:before, .wrapper-susy:after {
    content: " ";
    display: table;
}
.wrapper-susy:after {
  clear: both;
}