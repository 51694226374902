                                        ///*------------------------------------*\
//    #PAGE-FOOT
//\*------------------------------------*/
.page-foot__us * {
    color: $color-page-foot-text;
}
.page-foot__us li {
    list-style-image: url(../img/footer/bullet.gif);
}
.page-foot__us__simple {
    @include span(1 of 4);
    @include media-query(portable) {
        @include span(1 of 3);
    }
}
.page-foot_link-social {
    background-color: $color-main !important;
}
.page-foot__content-social {
    justify-content: space-evenly !important;
}
// .page-foot__social {
//     border-top: none !important;
// }