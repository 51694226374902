///*------------------------------------*\
//    #MODALS - CUSTOM FROM BOOTSTRAP
//\*------------------------------------*/

//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit


//Internal variables
$modal-lg:                    900px;
$modal-md:                    90%;
$modal-sm:                    300px;


// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal;
    -webkit-overflow-scrolling: touch;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;

    // When fading in the modal, animate it to slide down
    &.fade .modal-dialog {
        transform: translate(0, -25%);
        transition: transform 0.3s ease-out;
    }
    &.in .modal-dialog { translate: (0, 0); }
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
    // Automatically set modal's width for larger viewports
    @include media-query(lap-and-up){
        width: $modal-md;
        margin: 30px auto;
    }
}

// Actual modal
.modal-content {
    position: absolute;
    width: 100%;
    background-color: $color-modal-bg;
    border: 1px solid $color-modal-border;
    border-radius: $base-round;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    background-clip: padding-box;  
    // Remove focus outline from opened modal
    outline: 0;
    @include media-query(lap-and-up){
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-modal - 1;
    background-color: $color-modal-backdrop-bg;
    // Fade for backdrop
    &.fade { opacity: 0; }
    &.in { opacity: 0.5; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding: $inuit-base-spacing-unit;
    border-bottom: 1px solid $color-modal-head;
    background-color: $color-modal-head;
    min-height: 60px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
// Close icon
.modal-header .close {
    margin-top: -2px;
}

// Title text within header
.modal-title {
    margin: 0;
    color: $color-modal-head-color;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    min-height: 180px;
    position: relative;
    padding: $inuit-base-spacing-unit;
}

// Footer (for actions)
.modal-footer {
    padding: $inuit-base-spacing-unit;
    text-align: right; // right align buttons
    border-top: 1px solid $color-modal-border;
    @extend .clearfix; // clear it in case folks use .pull-* classes on buttons

    // Properly space out buttons
    .btn + .btn {
        margin-left: 5px;
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
    // but override that for button groups
    .btn-group .btn + .btn {
        margin-left: -1px;
    }
    // and override it for block buttons as well
    .btn-block + .btn-block {
        margin-left: 0;
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}


// Modal sizes
.modal-sm { 
    @include media-query(lap-and-up){
        width: $modal-sm;
    }
}

.modal-lg { 
    @include media-query(desk){
        width: $modal-lg;
    }
}





button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.close {
  float: right;
  font-size: ($inuit-base-font-size * 2);
  @include font-weight(bold);
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 white;
  opacity: 0.25;

  @include attention() {
    color: black;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

}
