///*------------------------------------*\
//    #AVAILABILITY
//\*------------------------------------*/

.dispo-flight__content {
    @include span(5 of 7);
    margin-bottom: 0;
    @include media-query(palm) {
        @include span(full);
    }
    @include media-query(lap-and-up) {
        padding-right: 36px;
    }
}

.dispo-flight__sidebar {
    @include span(2 of 7);
    border-radius: $base-round;
    color: black;
    @include media-query(palm) {
        @include span(full);
    }
    @include media-query(lap-and-up) {
        position: absolute;
        top: $inuit-base-spacing-unit;
        right: $inuit-base-spacing-unit;
        bottom: 0;
        padding: $inuit-base-spacing-unit;
    }
}

.dispo-shared__sidebar {
    & .search-flight__options .first-option {
        padding-top: $inuit-base-spacing-unit--small;
    }
    & .search-flight__options .last-option .form-group {
        margin-bottom: $inuit-base-spacing-unit--small;
    }
    & .search__row-main .section--first {
        margin-bottom: $inuit-base-spacing-unit;
    }
}

.dispo-flight__sidebar_special {
    @include span(2 of 7);
    @include redGradient();
    box-shadow: inset 0 40px 60px -10px rgba(0, 0, 0, .2);
    border-radius: $base-round;
    color: white;
    @include media-query(palm) {
        @include span(full);
    }
    @include media-query(lap-and-up) {
        position: relative;
        float: right;
    }
}

.dispo-flight__sidebar.dispo-flight__sidebar_special_aviatur,
.dispo-flight__sidebar_special.dispo-flight__sidebar_special_aviatur {
    @include blackGradient();
}

.dispo-flight__sidebar_special_aviatur .btn--submit{
    background-color: rgba(255,0,0,.4);
    background-image: url("/assets/aviatur_assets/img/promo/black_friday_fire.gif");
}

.dispo-flight__title-wrapper {
    clear: left;
    margin-top: $inuit-base-spacing-unit--small;
}





#owl-dispo-flight {
    width: 100%;
    margin-bottom: 24px;
}
#owl-dispo-flight .owl-controls {
    position: absolute;
    margin-top: 0;
    width: 100%;
}
.dispo-flight__best {
    width: calc(100% - #{$inuit-base-spacing-unit});
    margin: 0 $inuit-base-spacing-unit--small;
    padding-top: 5px;
}
.dispo-flight__best img {
    max-width: $inuit-base-spacing-unit--large;
}
.dispo-flight__best__title {
    @include font-size(11px);
    margin-bottom: 2px;
}
.dispo-flight__best__price {
    color: $color-dispo;
    font-weight: bold;
    word-break: break-all;
    @media screen and (min-width: 769px) and (max-width: 1600px) {
        font-size: 1vw;
    }
    @media screen and (max-width: 768px) {
        font-size: 2vw;
    }
    @media screen and (max-width: 479px) {
        font-size: 3.5vw;
    }
}




.dispo-flight__frame {
    @include media-query(palm) {
        padding-top: $inuit-base-spacing-unit;
    }
    @include media-query(lap-and-up) {
        min-height: 245px;
    }
}
.dispo-flight__pick {
    margin-bottom: $inuit-base-spacing-unit;
    text-align: center;
    @include media-query(palm) {
        margin-bottom: $inuit-base-spacing-unit;
    }
}
.dispo-flight__row-title {
    border-bottom: 1px solid $color-dispo-border;
    margin-bottom: $inuit-base-spacing-unit--small;
    padding-bottom: $inuit-base-spacing-unit--tiny;
}
.dispo-flight__row__icon {
    vertical-align: baseline;
    color: $color-dispo-icons;
    font-size: 22px;
    line-height: $inuit-base-line-height;
    padding-left: 0 !important;
    padding-right: 0 !important;
    @include media-query(palm) {
        font-size: 20px;
    }
}
.dispo-flight__row {
    border-radius: $base-round;
    padding-top: 3px;
    padding-bottom: 5px;
    cursor: pointer;
    transition: background-color .3s ease;
}
.dispo-flight__row:hover {
    background-color: lighten($color-dispo-selected, 38%);
}
.dispo-flight__row *, .dispo-flight__row-title * {
    @include media-query(palm) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.dispo-flight__row__check {
    @include span(1 of 20);
}
.dispo-flight__row__origin {
    @include span(14 of 100);
}
.dispo-flight__row-title .dispo-flight__row__origin {
    color: $color-dispo;
    @include font-weight(bold);
}
.dispo-flight__row__arrow {
    @include span(3 of 100);
}
.dispo-flight__row__destination {
    @include span(14 of 100);
    @media only screen and (max-width:1144px) {
        @include span(16 of 100);
}
}
.dispo-flight__row-title .dispo-flight__row__destination {
    color: $color-dispo;
    @include font-weight(bold);
}
.dispo-flight__row__destination .delay-container {
    position: relative;
}
.dispo-flight__row__destination .day-delay{
    position: absolute;
    top: 0;
    font-size: 11px;
    color: $color-dispo;
    @include media-query(lap-and-up) {
        left: 3px;
    }
}
.dispo-flight__row-title .dispo-flight__row__date {
    color: $color-dispo;
}
.dispo-flight__row-title .dispo-flight__row__stops {
    color: $color-dispo;
}
.dispo-flight__row__airline .icon_magnifying-glass{
    margin-left: 5px;
    @include media-query(palm) {
        display: none;
    }
}
.dispo-flight__seats {
    margin-top:-$inuit-base-spacing-unit--small;
}
.dispo-flight__sidebar_special .dispo-flight__seats {
    margin-top: -$inuit-base-spacing-unit--small;
}

.dispo-flight__sidebar_special_aviatur{
    color:#fff;
}

/*.dispo-flight__row__seats {
    @include span(1 of 4);
    padding-left: 0;
}*/
.dispo-flight__row__seats {    
    @include span(17 of 100);
    @media only screen and (max-width:1144px) {
        @include span(8 of 100);
    }
    @include media-query(palm) {
        width: 100%;
    }
}
.dispo-flight__row__seats p{
    font-size: 12px;
    color: red;
    margin: 0;
    @include media-query(portable) {
        font-size: 10px;
    }
}
.dispo-flight__row .dispo-flight__row__airline {
    @include font-size(12px);
}
.dispo-flight__row__seats-logo {
    display: block;
    margin: auto;
    max-height: round($inuit-base-spacing-unit * 2);
}




.dispo-flight__filter__slider {
    margin: 0 $inuit-base-spacing-unit--small $inuit-base-spacing-unit--small;
}

.dispo-flight__submit-total {
    font-size: 22px;
    @include font-weight(bold);
    @include media-query(lap-and-up) {
        font-size: 26px;
    }
}

.dispo-flight__airport-change {
    background:$color-main;
    color:#fff;
    border-radius:4px;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.dispo-flight__selection-resume {
    padding: 0;
}

.flight-selection__trip {
    background-color: rgba($gray-base, .03);
    padding: $inuit-base-spacing-unit--small;
    border-bottom: 1px solid $color-frame-border;
    @include media-query(palm) {
        padding: 0;
    }
    @include media-query(portable) {
        &:not(:last-child) {
            margin-bottom: $inuit-base-spacing-unit--small;
        }
    }
    &:active, &.active {
        background-color: lighten($color-brand, 46%);
        border-color: lighten($color-brand, 40%);
    }
}
.flight-selection__trip-date {
    margin-top: .2rem;
}
.flight-selection__airline-img {
    max-height: 1em;
    vertical-align: top;
    margin-top: 3px;
    padding-left: $inuit-base-spacing-unit--small/2;
}
.flight-selection__dart .icon {
    margin-top: -$inuit-base-spacing-unit--small;
    font-size: $inuit-base-spacing-unit--small;
}
.flight-selection__half-container {
    width: 50%;
    float: left;
    & * {
        @extend .overflow-ellipsis;
    }
}


/*--------------------------
* - FARE FAMILIES
*--------------------------*/

.dispo-flight__ff-container {
    width: 100%;
    @include media-query(desk) {
        @include span(6 of 8);
    }
}

.ff__table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border: 0;
    text-align: center;
    background-color: #fff;
    @include media-query(palm) {
        &, & thead, & tbody, & th, & td, & tr {
            display: block;
        }
    }
    /* here be kind of the worst cross browser hack ever... but still works in 2017 ¯\_(ツ)_/¯ */
    @include media-query(lap-and-up) {
        height: 100%;
        & tr {
            height: 100%;
            & th,
            & td {
                height: 1px;
                & .ff__fare-row {
                    /*position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;*/
                    height:100%;
                }
            }
        }
    }
}

.ff__table th,
.ff__table td {
    line-height: 1.42857;
    vertical-align: middle;
}

.ff__th-fare {
    .ff__table & {
        @include media-query(palm) {
            display: none;
        }
    }
}

.ff__th-flight {
    width: 42%;
    .ff__table tr > & {
        height: 4.2rem;
    }
    @include media-query(palm) {
        width: 100%;
        & .ff__fare-row {
            height: 100%;
        }
    }
}

.ff__fare-block {
    display: table;
    width: 100%;
    height: 100%;
    float: left;
    border-bottom: 2px solid #ddd;
    overflow: auto;
    @include media-query(lap-and-up) {
        width: 30%;
    }
}
.ff__fare-block--condensed {
    @extend .ff__fare-block;
    @include media-query(lap-and-up) {
        width: 20%;
    }
}
.ff__fare-content {
    text-align: center;
    padding: $inuit-base-spacing-unit--small;
    width: 100%;
    .ff__fare-first-cell & {
        @include media-query(palm) {
            display: table-cell;
            vertical-align: middle;
        }
    }
    @include media-query(lap-and-up) {
        display: table-cell;
        vertical-align: middle;
        padding: $inuit-base-spacing-unit--small/2;
    }
}

.ff__fare-cell {
    @include media-query(palm) {
        min-height: 4em;
    }
    .ff__flight-option.highlight .ff__flight-info &,
    .ff__flight-option.highlight-selected .ff__flight-info & {
        background-color: rgba(0,0,0,.03);
    }
    @include media-query(lap-and-up) {
        .ff__flight-option.highlight .ff__fare-row &,
        .ff__flight-option.highlight-selected .ff__fare-row & {
            background-color: rgba(0,0,0,.03);
        }
    }
}

.ff__flight-option {
    @include media-query(palm) {
        clear: both;
        &:before, &:after {
            clear: both;
            content: " ";
            display: table;
        }
        transition: box-shadow .3s;
        &.ff__flight-option.highlight {
            box-shadow: 0 3px 9px 1px rgba(0,0,0,.3);
        }
    }
}

.ff__flight-info {
    width: 42%;
    @include media-query(palm) {
        width: 100%;
    }
}
.ff__flight-info-content {
    padding: $inuit-base-spacing-unit--small;
    border-left: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    @include media-query(palm) {
        border-right: 1px solid #ddd;
        border-bottom: 1px dashed #ddd;
    }
    &.highlight {
        background-color: rgba(0,0,0,.03);
    }
}

.ff__fare-airline {
    width: 14%;
    float: left;
    padding-right: $inuit-base-spacing-unit--small;
    @include media-query(lap) {
        display: block;
        margin-bottom: $inuit-base-spacing-unit--small;
        text-align: left;
    }
    @include media-query(palm) {
        width: 100%;
        padding: 0;
        text-align: left;
    }
}
.ff__fare-airline img {
    max-height: $inuit-base-spacing-unit;
    max-width: 60px;
}

.ff__schedule {
    width: 86%;
    float: left;
    @include media-query(palm) {
        width: 100%;
    }
}
.ff__schedule-destination {
    float: left;
    width: 50%;
    line-height: 1.1;
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    @include media-query(lap) {
        width: 46%;
    }
    @include media-query(palm) {
        text-align: center;
    }
    &:first-child {
        padding-right: $inuit-base-spacing-unit--small;
        @include media-query(lap) {
            padding-right: 0;
            margin-right: 8%;
        }
    }
}
.ff__time {
    font-size: $inuit-base-spacing-unit;
    font-weight: bold;
}
.ff__airport {
    @include media-query(lap) {
        word-break: break-word;
    }
}
.ff__more-info {
    padding-top: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        padding-top: 4px;
    }
}

.ff__price-info {
    @include media-query(palm) {
        width: 75%;
        margin: 0 0 0 auto;
        .ff__fare-option--disabled & {
            margin-top: $inuit-base-spacing-unit--small;
        }
    }
}
.ff__currency {
    display: block;
}
.ff__info {
    position: absolute;
    top: 0;
    right: 0;
    padding: $inuit-base-spacing-unit--small/2;
    line-height: 1em;
}
.ff__price-na {
    padding: $inuit-base-spacing-unit--small/2;
}

.ff__palm-header {
    @include media-query(palm) {
        float: left;
        width: 25%;
        margin-top: $inuit-base-spacing-unit--small;
    }
}

.ff__fare-first-cell {
    width: 100%;
    background-color: transparent;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 2px solid #ddd;
    @include media-query(palm) {
        border-right: 1px solid #ddd;
    }
}

.ff__fare-header {
    color: #fff;
    border-top: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    border-bottom: 2px solid rgba(0,0,0,.1);
    &:last-child {
        border-right: 1px solid rgba(0,0,0,.1);
    }
}

%ff__fare-option {
    font-size: 14px;
    font-weight: bold;
    @include media-query(palm) {
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        &:not(:last-child) {
            border-bottom: 1px dashed #ddd;
        }
    }
    @include media-query(lap-and-up) {
        &:first-child,
            .ff__fare-option--prime:first-child + & {
            border-left: 1px dashed #ddd;
        }
        &:last-child {
            border-right: 1px solid #ddd;
        }
        &:not(:last-child) {
            border-right: 1px dashed #ddd;
        }
    }
    /*    &.ff__best-price-all:not(.active):not(.selected) {
            color: #fff;
            & .ff__fare-content {
                background-color: #00cc5f;
            }
        }*/
}
%ff__fare-option-clickable {
    @include media-query(lap-and-up) {
        &.active:not(.selected) {
            color: #FCFCFC;
            text-shadow: 2px 2px rgba(0,0,0,0.2);
        }
        &.selected {
            color: rgba(0,0,0,0.7);
        }
    }
}
.ff__fare-option {
    @extend %ff__fare-option;
    @extend %ff__fare-option-clickable;
}
.ff__fare-option--disabled {
    @extend %ff__fare-option;
}
.ff__fare-option--prime {
    @extend %ff__fare-option;
    @include media-query(palm) {
        min-height: auto;
        font-weight: normal;
        margin-top: -1px;
        &:not(:last-child) {
            border-bottom: 2px solid #ddd;
        }
    }
}

.ff__point-bestFare {
    position: absolute;
    bottom: $inuit-base-spacing-unit--small/2;
    right: $inuit-base-spacing-unit--small/2;
    color: #00cc5f;
    .ff__fare-cell.active &,
    .ff__fare-cell.selected & {
        color: inherit;
    }
}

.ff__fare-info-tooltip {
    position: absolute;
    max-width: 300px;
    border: 1px solid transparent;
}
.ff__tooltip-box {
    padding: $inuit-base-spacing-unit--small;
    background-color: #fbfbfb;
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 1px 4px 0px rgba(0,0,0,.4);
}
.ff__tooltip-box-title {
    margin-bottom: $inuit-base-spacing-unit--small/2;
}
.ff__tooltip-list {
    list-style: none;
    margin: 0;
    & .ffc--0 {
        color: red;
    }
    & .ffc--1 {
        color: orange;
    }
    & .ffc--2 {
        color: green;
    }
}
.ff__tooltip-pointer {
    position: absolute;
    top: 0;
    width: 12px;
    height: 13px;
    overflow: hidden;
    z-index: 10;
    &.left-side {
        left: calc(50% - 12px);
    }
    &.left-side > * {
        transform: skewX(45deg);
        transform-origin: 0 0 0;
        border-left: 1px solid rgba(0,0,0,.2);
    }
    &.right-side {
        left: 50%;
    }
    &.right-side > * {
        transform: skewX(-45deg);
        transform-origin: 5px 0 0;
        border-right: 1px solid rgba(0,0,0,.2);
    }
    &.left-side > *,
    &.right-side > * {
        width: inherit;
        height: inherit;
        background-color: #fbfbfb;
        box-shadow: 0 1px 3px rgba(0,0,0,.6);
    }
}