///*------------------------------------*\
//    #DETAIL-SHARED
//\*------------------------------------*/


.detail-shared__dispo-back {
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(desk) {
        float: left;
        margin-top: $inuit-base-spacing-unit;
    }
}


.detail-shared__id-connect {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include media-query(lap-and-up) {
        float: right;
    }
}


.detail-shared__same-billing {
    color: $color-detail-same-billing;
    float: left;
    padding: $inuit-base-spacing-unit--small;
    @include media-query(lap-and-up) {
        margin-left: $inuit-base-spacing-unit;
    }
}
.detail-shared__same-billing * {
    @include font-size(14px);
}

.detail-shared__baggage * {
    @include font-size(14px);
}

.detail-shared__payment-toggle {
    @include media-query(lap-and-up) {
        margin-top: $inuit-base-spacing-unit;
    }
}


.detail-shared--payment-name {
    @include span(5 of 14);
    @include media-query(palm) {
        @include span(full);
    }
}
.detail-shared--payment-type {
    @include span(4 of 14);
    @include media-query(palm) {
        @include span(65 of 100);
    }
}

.detail-shared--payment-cuota {
    @include span(12 of 100);
    @include media-query(palm) {
        @include span(35 of 100);
    }
}
.detail-shared--payment-card {
    @include span(34 of 100);
    @include media-query(palm) {
        @include span(full);
    }
}
.detail-shared--payment-expi {
    @include span(30 of 100);
    @include media-query(palm) {
        @include span(full);
    }
}
.detail-shared--payment-verif {
    @include span(24 of 100);
    @include media-query(palm) {
        @include span(full);
    }
}
.detail-shared--payment-date select {
    float: left;
    width: 50%;
}

.detail-shared__payment-header {
    padding: $inuit-base-spacing-unit--small $inuit-base-spacing-unit;
}


#detailForm .circularG {
    float: left;
    &.circularG--sameBilling {
        margin-top: $inuit-base-spacing-unit--small;
    }
    @include media-query(palm) {
        position: absolute;
        right: 0;
        margin-right: $inuit-base-spacing-unit;
    }
}

#detailForm .icon__input {
    visibility: hidden;
}

.box-shadow-div {
    border-radius: $base-round;
    transition: box-shadow 2s;
    &.flash {
        box-shadow: 2px 3px 37px 10px rgba(0,0,0,.75);
    }
}

.cantTouchThis {
    & .icon__input, & .text-input {
        color: rgba(0,0,0,.2);
    }
}

.detail__coupon_text {
    float: left;
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 1.5;
    @include media-query(palm) {
        width: 100%;
    }
}

.detail__coupon_input {
    float: left;
    width: 25%;
    @include media-query(palm) {
        width: 50%;
    }
}

.detail__coupon_button {
    float: left;
    width: 25%;
    @include media-query(palm) {
        width: 50%;
    }
}

.detail__payment-method {
    & .nav-option {
        width: 50%;
        @include media-query(lap-and-up) {
            width: 25%;
        }
        @include media-query(palm) {
            border-width: 1px;
        }
        & img {
            width: 30px;
        }
        & .tooltips-trigger {
            display: inline-block;
        }
    }
}
