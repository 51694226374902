///*------------------------------------*\
//    #PROMO-BOX
//\*------------------------------------*/

// Internal variable
$promo-box-height: 260px;

.promo-box__grid  {
    @include span(1 of 3);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}
.promo-box__title, .promo-box__subtitle {
    padding: $inuit-base-spacing-unit--tiny 0;
    width: 100%;
    transition: all 0.45s;
}
.promo-box:hover .promo-box__title, .promo-box:hover .promo-box__subtitle {
    background-color: rgba($color-main, 0.8);
}
.promo-box__title *, .promo-box__subtitle * {
    color: white;
    margin-bottom: 0;
    @include font-weight(bold);
}
.promo-box__subtitle * {
    line-height: 1.2;
}



.promo-box__link {
    background-color: rgba(black, 0.65);
    color:#fff;
    opacity: 0;
    padding: $inuit-base-spacing-unit--small 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity 0.45s;
    @include font-size(14px);
    @include font-weight(bold);
    box-shadow: 0px 4px 20px -10px rgba(0,0,0,0.75);
}
.promo-box:hover  .promo-box__link {
    opacity: 1;
}
.promo-box__conditions {
    background-color: rgba(black, 0.65);
    bottom: 0;
    color:#fff;
    cursor: pointer;
    opacity: 0;
    padding: $inuit-base-spacing-unit--small;
    position: absolute;
    width: 100%;
    transition: opacity 0.45s;
    @include font-size(11px);
    box-shadow: 0px -4px 20px -10px rgba(0,0,0,0.75);
}
.promo-box:hover  .promo-box__conditions {
    opacity: 1;
}
.carouselh .slidesh .slideItem a .inner-slide .info-slide .subtitle-info .price-info {
    color: $color-main;
}
.buttonNav {    
    .bullet {    
    background-color: lighten($color-main, 40%) !important;  
    }
    .bulletActive, .bulletActive{
        background-color: $color-main !important;
    }
}
.btn--slide-scroll {    
    color: $color-main;
    border: 2px solid $color-main;
}
.content-title-promo{
    @include media-query(desk){
        padding-left: 14px;
    }
}