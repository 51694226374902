/*------------------------------------*\
    #FORMS
\*------------------------------------*/

input[type='checkbox'] {
    height: 12px;
    margin: 0;
    vertical-align: middle;
}

.list-checkbox {
    display: inline-block;
    position: relative;
    height: 10px;
    width: 10px;
    border: 1px solid $color-main;
    border-radius: 5px;
}
.list-checked {
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: $color-main;
    border-radius: 5px;
}