                                                                                                    ///*------------------------------------*\
//    #NAV-TOP
//\*------------------------------------*/

// Internal variables.
$logo-height:   108px;
$mobile-navbar-height:      $logo-height + (2*$inuit-base-spacing-unit);
$mobile-menu-img-height:    170px;

.page-head,
%page-head {
    position: relative;
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm) {
        background-color: transparent;
    }
}
%page-head-bg-container {
    position: absolute;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}.page-head__bg-container--responsive {
    @extend %page-head-bg-container;
    height: 80px ;
    background-color: $color-main;
    @include material-shadow(2);
} 
.page-head__bg__img {
    &.bg-img_background_default     { background-image: url(../img/header/covers/snapshots/background_default.jpg); }
    &.bg-img_background_special       { background-image: url(../img/header/covers/snapshots/background_special.jpg); }
}
.page-head--responsive {
    @include media-query(palm) {
        margin-left: -$inuit-base-spacing-unit--small;
        width: calc(100% + #{$inuit-base-spacing-unit});
    }
}
.page-head__menu-mobile{
    margin-top: 5px;
}       
.page-head__logo{
    @include media-query(lap-and-up) {
        margin-top: 0;
        margin-right: 100px;
    }
}
.page-head__aviatur {
    max-height: $logo-height;    
    @include media-query(lap-and-up) {
        float: left;
        padding-right: 0px;
        margin-top: 0px;
    }
    @include media-query(palm) {    
        max-height: 65px;
        margin-left: 3px;
        margin-top: 7px;
    }
} 